import React from 'react';
import './HeroSection.css';
import cupcake_chocolate from './../images/Cupcake-chocolate.jpg';

const HeroSection = (props) => {
  return (
    <div className="hero-section">
      <img src={cupcake_chocolate} alt="Imagen Principal" />
      <div className="hero-text">
        <h1>LA CASA DEL CUPCAKE</h1>
        <p>El hogar de los cupcakes más deliciosos en la CDMX.</p>
      </div>
      <button className="hero-button" onClick={props.functionOpenOverlay}>¡COMPRA YA!</button>
    </div>
  );
};

export default HeroSection;
