import React from 'react';
import './InnovativeTitle.css';

const InnovativeTitle = ({ title }) => {
  return (
    <div className="innovative-title-container">
      <h1 className="innovative-title">{title}</h1>
      <div className="innovative-title-underline"></div>
    </div>
  );
};

export default InnovativeTitle;
