import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';
import logo from './../images/logo_squared.jpeg';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import chocolate from './../images/chocolate.jpeg';
import frambuesa from './../images/frambuesa.jpeg';
import vainilla from './../images/vainilla.jpeg';

const Navbar = (props) => {
  const [scrolling, setScrolling] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [showCopyIcon, setShowCopyIcon] = useState(false);

  const [clickedOptions, setClickedOptions] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  

  const handleWhatsAppClick = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText('+52 (55) 3197 0894');
    setShowCopyIcon(true);
    setTimeout(() => setShowCopyIcon(false), 1000);
    window.open("https://wa.me/+525531970894") // Hide the copy icon after 1 second
  };

  const enterHandlerOptions = (event) => {
    if (clickedOptions === false){
      setDropdownActive(true)
    }
  };

  const leaveHandlerOptions = () => {
    if (clickedOptions === false){
      setDropdownActive(false);
    }
  };

  const clickHandlerOptions=() => {
    if (clickedOptions === true){
      setDropdownActive(false);
      setClickedOptions(false);
    }
    else{
      setDropdownActive(true);
      setClickedOptions(true);
    };
  };

  const bluredOptions=() => {
      setClickedOptions(false);
      setDropdownActive(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 10) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);



  return (
    <div className={`navbar${scrolling ? ' scrolling' : ''}`}
      //onMouseEnter={() => dropdownActive && enterHandlerOptions()}
      onMouseLeave={leaveHandlerOptions}
      onBlur={bluredOptions}
    >
      <div
        className="dropdown"
      >
        <button className={`nav-button${dropdownActive ? ' hover' : ''}`}
          onMouseEnter={enterHandlerOptions}
          onClick={clickHandlerOptions}
        >
          PRODUCTOS
          <span className={`arrow`}/>
        </button>
        <div 
          className={`dropdown-content${dropdownActive ? ' active' : ''} ${scrolling ? '' : 'no-scroll'}`}
        >
          <div 
            className="nav-dropdown" 
          >
            <table className='table-dropdown'>
              <tbody className='tbody-dropdown'>
                <tr>
                  <td onMouseDown={props.functionOpenOverlay}>SABORES CLÁSICOS</td>
                  <td rowSpan="6" className="image-container" onMouseDown={props.functionOpenOverlay}>
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      autoPlay={true}
                      interval={3000}
                      infiniteLoop={true}
                      centerMode={true}
                      centerSlidePercentage={50}
                    >
                      <div className='carousel-image'>
                        <img  src={chocolate} alt="feature1" />
                      </div>
                      <div className='carousel-image'>
                        <img src={frambuesa} alt="feature2" />
                      </div>
                      <div className='carousel-image'>
                        <img src={vainilla} alt="feature3" />
                      </div>
                    </Carousel>
                  </td>
                </tr>
                <tr>
                  <td onMouseDown={props.functionOpenOverlay}>SABORES DE TEMPORADA</td>
                </tr>
                <tr>
                  <td onMouseDown={props.functionOpenOverlay}>DISPONIBILIDAD LIMITADA</td>
                </tr>
                <tr>
                  <td onMouseDown={props.functionOpenOverlay}>PERSONALIZADOS</td>
                </tr>
                <tr>
                  <td hidden rowSpan="6" className="image-container2" onMouseDown={props.functionOpenOverlay}>
                  <Carousel
                      showThumbs={false}
                      showStatus={false}
                      autoPlay={true}
                      interval={3000}
                      infiniteLoop={true}
                      centerMode={true}
                      centerSlidePercentage={50}
                    >
                      <div className='carousel-image'>
                        <img  src={chocolate} alt="feature1" />
                      </div>
                      <div className='carousel-image'>
                        <img src={frambuesa} alt="feature2" />
                      </div>
                      <div className='carousel-image'>
                        <img src={vainilla} alt="feature3" />
                      </div>
                    </Carousel>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="social-media-icons">
        <a href="https://www.facebook.com/profile.php?id=100089997167914" target="_blank">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://www.instagram.com/lacasadelcupcake2012/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <div
          className="whatsapp-container"
          onMouseEnter={() => setShowPhoneNumber(true)}
          onMouseLeave={() => setShowPhoneNumber(false)}
        >
          <a href="#" onClick={handleWhatsAppClick}>
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
          {showPhoneNumber && (
            <div className="phone-number">
              <a href="tel:+52 (55) 3197 0894" onClick={handleWhatsAppClick}>
                <span>+52 (55) 3197 0894</span>
              </a>
            </div>
          )}
          {showCopyIcon && (
            <div className="copy-icon">
              <FontAwesomeIcon icon={faCopy} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
