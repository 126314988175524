import React from 'react';
import './OverlayWindow.css';
import construccion from './../images/en_construccion.jpg';

const OverlayWindow = ({ onClose }) => {

  const handleParentClick = event => {
    event.preventDefault();

    if (event.target === event.currentTarget) {
      onClose()
    }
  };

  const handleChildClick = event => {
  };

  const sendCode = () => {
    window.open("https://api.whatsapp.com/send?phone=+525531970894&text=TOC%20TOC!")
  };


  return (
    <div className="overlay" onClick={handleParentClick}>
      <div className="overlay-window" onClick={handleChildClick}>
        <h2>Ya estamos disponibles en Whatsapp</h2>
        <p>¡Realiza tu pedido ya! <a href='https://wa.me/+525531970894'>+52 55 3197 0894</a></p>
        <img className="overlay-image" src={construccion} alt="Your description" />
        <button className="close-button" onClick={sendCode}>
          ENVIAR CÓDIGO
        </button> 
      </div>
    </div>
  );
};

export default OverlayWindow;