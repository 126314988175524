import React from 'react';
import './ZigzagContent.css';

const ZigzagContent = ({ items }) => {
  return (
    <div>
        {items.map((item, index) => (
            <div key={index}>
                {item.id % 2 === 0 && (
                    <div className="zigzag-content-container">
                        <div className="zigzag-text">
                            <p>{item.description}</p>
                        </div>
                        <div className="zigzag-image">
                            <img src={item.image} alt={item.alt} />
                        </div>
                    </div>
                )}
                {item.id % 2 === 1 && (
                    <div className="zigzag-content-container">
                        <div className="zigzag-image">
                            <img className="zigzag-image" src={item.image} alt={item.alt} />
                        </div>
                        <div className="zigzag-text">
                            <p>{item.description}</p>
                        </div>
                    </div>
                )}
            </div>
        ))}
    </div>
  );
};

export default ZigzagContent;
