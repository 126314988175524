import React, { useState } from 'react';
import './App.css';
import InnovativeTitle from './components/InnovativeTitle';
import ZigzagContent from './components/ZigzagContent';
import HeroSection from './components/HeroSection';
import Navbar from './components/Navbar';
import OverlayWindow from './components/OverlayWindow';

import chocolate from './images/chocolate.jpeg';
import frambuesa from './images/frambuesa.jpeg';
import fresa from './images/fresa.jpeg';
import vainilla from './images/vainilla.jpeg';
import zarzamora from './images/zarzamora.jpeg';


const items = [
  {
    id: 1,
    image: zarzamora,
    alt: 'Zarzamora',
    description: `
    En La Casa del Cupcake, sabemos que el sabor es la clave para un buen cupcake. Es por eso que utilizamos solo los ingredientes de la más alta calidad, para garantizarte un sabor inigualable en cada bocado. Cada uno de nuestros cupcakes es elaborado artesanalmente, con dedicación y pasión, para que puedas disfrutar de la experiencia única que solo La Casa del Cupcake puede ofrecerte.
    `,
  },
  {
    id: 2,
    image: frambuesa,
    alt: 'Frambuesa',
    description: '¿Qué te parece un cupcake de chocolate intenso, con una cobertura suave y cremosa? ¿O uno de frambuesa fresca y ácida, con un relleno delicioso que te sorprenderá en cada mordida? ¿O quizás uno de fresa dulce y suave, o un clásico de vainilla cremosa? ¡Y no olvides nuestro cupcake de zarzamora, con su sabor único y delicioso! En La Casa del Cupcake, tenemos una gran variedad de sabores que seguro te encantarán.',
  },
  {
    id: 3,
    image: fresa,
    alt: 'Fresa',
    description: 'Pero eso no es todo, en La Casa del Cupcake también creamos diseños personalizados para que puedas sorprender a tus seres queridos en cualquier ocasión. ¿Tienes una boda o un cumpleaños próximamente? Déjanos crear el cupcake perfecto para la ocasión. Ya sea que quieras una decoración clásica o algo más atrevido y original, nuestro equipo de expertos está listo para hacer realidad tu sueño.',
  },
  {
    id: 4,
    image: vainilla,
    alt: 'Vainilla',
    description: 'Y si te preocupa el precio, ¡no te preocupes! En La Casa del Cupcake, ofrecemos precios justos y competitivos, para que puedas disfrutar de los mejores cupcakes sin romper el banco. Además, nuestra tienda en línea está diseñada para hacerte la vida más fácil. Puedes hacer tu pedido en línea, desde la comodidad de tu hogar, y nosotros nos encargamos del resto. Y si tienes alguna pregunta o duda, nuestro equipo de atención al cliente está listo para ayudarte en todo lo que necesites.',
  },
  {
    id: 5,
    image: chocolate,
    alt: 'Chocolate',
    description: 'En La Casa del Cupcake, nos apasiona crear cupcakes que deleiten los sentidos y te hagan sentir especial en cualquier ocasión. Ya sea para una reunión con amigos, un regalo de cumpleaños o simplemente para darte un gusto, nuestros cupcakes son la mejor opción. Así que, si buscas el mejor lugar para comprar cupcakes en la Ciudad de México, ¡no busques más! Haz tu pedido ahora y deja que La Casa del Cupcake te haga sentir como en casa.',
  }
];

function App() {

  const [showOverlay, setShowOverlay] = useState(false);

  const openOverlay = () => {
    setShowOverlay(true);
  };
  
  const closeOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div className="App">
      <Navbar functionOpenOverlay={openOverlay}/>
      <HeroSection functionOpenOverlay={openOverlay}/>
      <InnovativeTitle title="lA CASA DEL CUPCAKE" />
      <h3 className='App-header'>¡Bienvenidos a La Casa del Cupcake, la tienda de cupcakes más deliciosa y reconocida de la Ciudad de México!</h3>
      <ZigzagContent items={items} />
      {showOverlay && <OverlayWindow onClose={closeOverlay} />}
    </div>
  );
}

export default App;





